// fallback image if image value is empty
// square size
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FallbackImgSquare = ({ className }) => {
  return (
    <StaticImage
      className={className}
      src="../../images/if-empty-event-square.png"
      alt="Preview"
    />
  );
};

export default FallbackImgSquare;
