// fallback image if image value is empty
// landscape size
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FallbackImgLandscape = ({ className }) => {
  return (
    <StaticImage
      className={className}
      src="../../images/if-empty-event-landscape.png"
      alt="Preview"
    />
  );
};

export default FallbackImgLandscape;
