// member modal
import React, { useEffect } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import {
  mainRed,
  mainBlack,
  screen,
  mainYellow,
  mainWhite,
  siteWidth,
} from "../common/variables";
import StyledButton from "../../components/styled-button";

const Wrapper = styled.div`
  background: rgba(43, 43, 43, 0.92);
  color: ${mainBlack};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;

  .modal {
    display: flex;
    padding: 60px 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: ${siteWidth};
    transform: translate(-50%, -50%);
    background: ${mainWhite};
    max-width: 1200px;
    height: calc(100% - 54px);
    width: calc(100% - 34px);
    overflow: scroll;
    @media ${screen.xsmall} {
      padding: 80px 50px;
      width: 100%;
      height: auto;
    }
    @media ${screen.small} {
      padding: 100px 60px;
    }

    .text-wrapper {
      .capital-heading {
        margin: 15px 0 0 0;
      }

      .normal-p {
        margin: 10px 0 0 0;
        display: block;
        line-height: 1.5;
        .slash-symbol {
          color: ${mainRed};
        }

        a {
          color: ${mainBlack};
        }
      }

      .description {
        margin: 30px 0;
        max-width: 950px;
        @media ${screen.xsmall} {
          margin: 30px 0 45px 0;
        }

        p {
          font-size: 1rem;
          font-weight: 300;
          margin: 0 0 20px 0;
          @media ${screen.large} {
            font-size: 1.1rem;
          }

          &:last-child {
            margin: 0;
          }
        }

        a {
          color: ${mainRed};
          text-decoration: underline;
          @media ${screen.withCursor} {
            &:hover {
              opacity: 0.7;
            }
          }
        }

        ul,
        ol {
          @media ${screen.small} {
            padding: 0 0 0 30px;
          }

          li {
            font-size: 1rem;
            font-weight: 300;
            margin: 0 0 20px 0;
            line-height: 1.5;
            @media ${screen.large} {
              font-size: 1.1rem;
            }
          }
        }

        ol {
          li {
            list-style-type: disc;
            list-style-position: inside;
          }
        }

        ul {
          li {
            list-style-type: decimal;
            list-style-position: inside;
          }
        }
      }

      .btns {
        display: flex;
        flex-direction: column;
        margin: 0 0 60px 0;
        @media ${screen.xsmall} {
          flex-direction: row;
          margin: 0;
        }

        .btn {
          margin: 0 0 18px 0;
          @media ${screen.xsmall} {
            margin: 0 10px;
          }
          @media ${screen.small} {
            margin: 0;
          }

          &--left {
            @media ${screen.small} {
              margin: 0 20px 0 0;
            }
          }
        }
      }
    }

    &__close-btn {
      background: none;
      cursor: pointer;
      border: none;
      border-radius: none;
      position: absolute;
      font-size: 2rem;
      font-weight: 700;
      top: 10px;
      right: 13px;
      color: ${mainYellow};
      outline: none;
      padding: 0;
      line-height: 30px;
      @media ${screen.xsmall} {
        font-size: 2.5rem;
        top: 20px;
        right: 20px;
      }
      @media ${screen.large} {
        font-size: 3rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
        }
      }
    }
  }
`;

const ModalEvent = ({ selectedEvent, setSelectedEvent }) => {
  useEffect(() => {
    // close modal if user clicked outside
    const userClick = (e) => {
      if (e.target.id === "outter-bg-event") {
        setSelectedEvent(null);
      }
    };

    // close modal if user pressed ESC key
    const userPress = (e) => {
      if (e.keyCode === 27) {
        setSelectedEvent(null);
      }
    };

    window.addEventListener("click", userClick);

    window.addEventListener("keyup", userPress);

    return () => {
      window.removeEventListener("click", userClick);
      window.removeEventListener("keyup", userPress);
    };
  }, []);

  return (
    selectedEvent && (
      <ScrollLocky>
        <Wrapper id="outter-bg-event">
          <div className="modal">
            <div className="text-wrapper">
              <h6 className="red-heading">UPCOMING EVENT</h6>
              <h3 className="capital-heading">{selectedEvent.event_title}</h3>
              <span className="normal-p">
                {selectedEvent.event_date}{" "}
                <span className="slash-symbol">//</span>{" "}
                {selectedEvent.event_time}{" "}
                <span className="slash-symbol">//</span>{" "}
                <a
                  href={selectedEvent.event_google_map_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedEvent.event_address}
                </a>
              </span>

              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: selectedEvent.event_description.html,
                }}
              />
              <div className="btns">
                <StyledButton className="btn btn--left" to="/volunteer/">
                  VOLUNTEER
                </StyledButton>
                <StyledButton className="btn" to="/donate/">
                  DONATE NOW
                </StyledButton>
              </div>
            </div>
            <button
              className="modal__close-btn"
              onClick={() => setSelectedEvent(null)}
            >
              X
            </button>
          </div>
        </Wrapper>
      </ScrollLocky>
    )
  );
};

export default ModalEvent;
