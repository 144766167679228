import React, { useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { BsArrowRight } from "react-icons/bs";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainWhite,
  mainRed,
  innerWidth,
  mainBlack,
  lightGrey,
  transHover,
  screen,
} from "../components/common/variables";
import FlipMove from "react-flip-move";
import StyledButton from "../components/styled-button";
import { BsArrowDown } from "react-icons/bs";
import { formatDate, toSlug, isFutureDate } from "../helpers";
import ModalEvent from "../components/modal-event";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";
import Cta from "../components/cta";
import fallBackbanner from "../images/singing-street-choir.png";
import FallbackImgSquare from "../components/fallback-images/fallback-event-img-square";
import FallbackImgLandscape from "../components/fallback-images/fallback-event-img-landscape";

const Wrapper = styled.div`
  .banner {
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        .inner-txt {
          text-align: center;
          @media ${screen.small} {
            text-align: left;
          }

          .capital-heading {
            margin: 20px 0 0 0;
          }

          .normal-p {
            margin: 20px 0 0 0;
            display: block;
            line-height: 1.5;
            .slash-symbol {
              color: ${mainRed};
            }

            a {
              color: ${mainBlack};
            }
          }

          .btn {
            margin: 20px 0 0 0;
          }
        }
      }
    }
  }

  .upcoming-events {
    background: ${mainWhite};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        .capital-subheading {
          text-transform: uppercase;
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
          @media ${screen.small} {
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        &--right {
          margin: 0 0 0 10px;

          .event-list {
            &__each {
              border-bottom: 2px ${mainRed} solid;
              position: relative;
              padding: 0 0 60px 0;
              margin: 0 0 28px 0;
              @media ${screen.xsmall} {
                padding: 0 0 16px 0;
              }

              .date-time {
                font-size: 0.88rem;
                font-weight: 600;
                margin: 0 0 8px 0;
                display: block;
                text-transform: uppercase;
                letter-spacing: 1px;
                @media ${screen.xsmall} {
                  font-size: 1rem;
                  margin: 0 0 6px 0;
                }
                @media ${screen.large} {
                  font-size: 1.1rem;
                }
              }

              .venue {
                font-size: 1.22rem;
                font-weight: 400;
                cursor: pointer;
                @media ${screen.xsmall} {
                  font-size: 1.4rem;
                }
                @media ${screen.small} {
                  max-width: 370px;
                }
                @media ${screen.medium} {
                  max-width: 480px;
                }
                @media ${screen.large} {
                  font-size: 1.5rem;
                }
              }

              .absolute-link {
                position: absolute;
                left: 0;
                bottom: 18px;
                display: flex;
                align-items: center;
                color: ${mainBlack};
                @media ${screen.xsmall} {
                  right: 0;
                  transform: translateY(-50%);
                  top: 50%;
                  left: auto;
                  bottom: auto;
                }
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainRed};
                  }
                }

                span {
                  font-size: 1.22rem;
                  font-weight: 400;
                  @media ${screen.xsmall} {
                    font-size: 1.4rem;
                  }
                  @media ${screen.large} {
                    font-size: 1.5rem;
                  }

                  svg {
                    font-size: 1.6rem;
                    vertical-align: middle;
                    color: ${mainRed};
                    margin: 0 0 0 10px;
                    @media ${screen.xsmall} {
                      font-size: 1.7rem;
                    }
                    @media ${screen.large} {
                      font-size: 1.8rem;
                    }
                  }
                }
              }

              &:last-child {
                margin: 0;
                border-bottom: 0;
                @media ${screen.xsmall} {
                  border-bottom: 2px ${mainRed} solid;
                }
              }
            }
          }
        }
      }
    }

    &--no-event {
      .inner-wrapper {
        padding: 60px 0 60px 0;
        @media ${screen.xsmall} {
          padding: 150px 42px;
        }
        @media ${screen.small} {
          padding: 150px 30px;
        }
        @media ${screen.large} {
          padding: 160px 0;
        }
      }

      .capital-subheading {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
      }
    }
  }

  .past-events {
    background: ${mainBlack};
    color: ${mainWhite};
    text-align: center;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    &__categories-list {
      display: none;
      @media ${screen.xsmall} {
        display: flex;
        justify-content: center;
        margin: 46px 0 36px 0;
        flex-wrap: wrap;
      }

      .category {
        cursor: pointer;
        border-bottom: 1px ${mainBlack} solid;
        font-size: 1rem;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 32px 32px 32px;
        transition: ${transHover};

        @media ${screen.withCursor} {
          &:hover {
            opacity: 0.85;
          }
        }

        &--selected {
          border-bottom: 1px ${mainWhite} solid;
        }
      }
    }

    &__categories-list-mobile-dropdown {
      padding: 30px 30px 30px 30px;
      position: relative;
      text-align: left;
      @media ${screen.xsmall} {
        display: none;
      }

      .placeholder {
        background: ${mainWhite};
        color: #959595;
        font-size: 0.69rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 14px 21px;
        position: relative;

        &__arrow {
          background: ${lightGrey};
          color: ${mainRed};
          position: absolute;
          display: flex;
          width: 43px;
          height: 100%;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          svg {
            font-size: 1.7rem;
            margin: auto;
          }
        }
      }

      .dropdown-list {
        background: ${lightGrey};
        padding: 0 22px;
        position: absolute;
        top: 74px;
        z-index: 10;
        width: 100%;
        max-width: 271px;

        .category {
          color: ${mainBlack};
          font-size: 1.25rem;
          padding: 13px 0;
          border-bottom: 2px ${mainRed} solid;

          &:last-child {
            border-bottom: none;
          }

          &--selected {
            span {
              opacity: 0.15;
            }
          }
        }
      }
    }

    .event-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      margin: 0 -9px;

      &__each {
        cursor: pointer;
        width: calc(100% - 18px);
        position: relative;
        margin: 0 9px 60px 9px;
        @media ${screen.xsmall} {
          width: calc(33.33% - 18px);
        }
        @media ${screen.small} {
          width: calc(25% - 18px);
        }
        @media ${screen.medium} {
          margin: 0 9px 18px 9px;
        }

        .img-wrapper {
          .desktop {
            display: none;
            @media ${screen.xsmall} {
              display: block;
            }
          }

          .mobile {
            @media ${screen.xsmall} {
              display: none;
            }
          }
        }

        .overlay {
          display: none;
          @media ${screen.medium} {
            background: rgba(255, 255, 255, 0.8);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: ${mainBlack};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            transition: ${transHover};
          }

          .heading {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 1px;
            margin: 0 0 22px 0;
            text-transform: uppercase;
            padding: 0 20px;
          }

          .btn {
            cursor: pointer;
            color: ${mainBlack};
            background: none;
            border: 2px ${mainBlack} solid;
            border-radius: 3px;
            font-size: 1.02rem;
            font-weight: 500;
            letter-spacing: 1px;
            padding: 10px 30px;
            text-transform: uppercase;
          }
        }

        .title-on-mobile {
          color: ${mainWhite};
          font-size: 1.02rem;
          font-weight: 500;
          letter-spacing: 2px;
          text-transform: uppercase;
          margin: 17px 0 0 0;
          padding: 0 11px;
          @media ${screen.medium} {
            display: none;
          }
        }

        .mobile-btn {
          margin: 17px 0 0 0;
          @media ${screen.medium} {
            display: none;
          }

          button {
            border: 2px ${mainWhite} solid;
            color: ${mainWhite};
          }
        }

        @media ${screen.withCursor} {
          &:hover .overlay {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }
`;

const EventsPage = ({ data }) => {
  // current event selected state, will open the modal if not null
  const [selectedEvent, setSelectedEvent] = useState(null);
  // events to render - only show if the date is in the future
  const filteredUpcomingEvents = data.upcomingEvents.data.event_list.filter(
    (item) => isFutureDate(item.event_date)
  );

  const featuredUpcomingEvent = {
    event_title:
      filteredUpcomingEvents.length !== 0 &&
      filteredUpcomingEvents[0].event_title.text,
    event_time:
      filteredUpcomingEvents.length !== 0 &&
      filteredUpcomingEvents[0].event_time,
    event_date:
      filteredUpcomingEvents.length !== 0 &&
      formatDate(filteredUpcomingEvents[0].event_date),
    event_description:
      filteredUpcomingEvents.length !== 0 &&
      filteredUpcomingEvents[0].event_description,
    event_address:
      filteredUpcomingEvents.length !== 0 &&
      filteredUpcomingEvents[0].event_address,
    event_google_map_url:
      filteredUpcomingEvents.length !== 0 &&
      filteredUpcomingEvents[0].google_map_url,
  };

  // all past event list
  const allPastEvents = data.pastEvents.edges;

  // all categories
  const allCategories = data.categories.edges
    .map((item) => item.node.data.title.text)
    .sort();

  // default selected category from array index
  const [selectedCategory, setSelectedCategory] = useState("All");

  // dropdown filtering for mobile used only
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // actual events that rendering to the page
  const filteredPastEvents =
    selectedCategory === "All"
      ? allPastEvents
      : allPastEvents.filter(
          (item) =>
            item.node.data.category.document &&
            item.node.data.category.document.data.title.text ===
              selectedCategory
        );

  // check if there's an upcoming event by array.length
  const isUpcomingEvent = filteredUpcomingEvents.length > 0;

  return (
    <Layout>
      <SEO title={"Events"} />
      <Wrapper>
        {isUpcomingEvent && (
          <SubBanner className="banner" bgUrl={fallBackbanner}>
            <div className="inner-wrapper">
              <div className="featured-txt">
                <div className="inner-txt">
                  <h6 className="red-heading">UPCOMING EVENT</h6>
                  <h1 className="capital-heading">
                    {featuredUpcomingEvent.event_title}
                  </h1>
                  <span className="normal-p">
                    {featuredUpcomingEvent.event_date}{" "}
                    <span className="slash-symbol">//</span>{" "}
                    {featuredUpcomingEvent.event_time}{" "}
                    <span className="slash-symbol">//</span>{" "}
                    <a
                      href={featuredUpcomingEvent.event_google_map_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {featuredUpcomingEvent.event_address}
                    </a>
                  </span>
                  <StyledButton
                    className="btn"
                    to={`/events`}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedEvent({
                        event_title: filteredUpcomingEvents[0].event_title.text,
                        event_time: filteredUpcomingEvents[0].event_time,
                        event_date: formatDate(
                          filteredUpcomingEvents[0].event_date
                        ),
                        event_description:
                          filteredUpcomingEvents[0].event_description,
                        event_address: filteredUpcomingEvents[0].event_address,
                        event_google_map_url:
                          filteredUpcomingEvents[0].google_map_url,
                      });
                    }}
                  >
                    LEARN MORE
                  </StyledButton>
                </div>
              </div>
            </div>
          </SubBanner>
        )}

        {isUpcomingEvent ? (
          <section className="upcoming-events">
            <div className="inner-wrapper">
              <div className="col">
                <h3 className="capital-subheading">
                  Upcoming Event{filteredUpcomingEvents.length > 1 && "s"}
                </h3>
              </div>
              <div className="col col--right">
                <ul className="event-list">
                  {filteredUpcomingEvents.map((item, i) => (
                    <li className="event-list__each" key={i}>
                      <span className="date-time">
                        {item.event_time}, {formatDate(item.event_date)}
                      </span>
                      <p
                        className="venue"
                        onClick={() => {
                          setSelectedEvent({
                            event_title: item.event_title.text,
                            event_time: item.event_time,
                            event_date: formatDate(item.event_date),
                            event_description: item.event_description,
                            event_address: item.event_address,
                            event_google_map_url: item.google_map_url,
                          });
                        }}
                      >
                        {item.event_title.text}
                      </p>

                      <Link
                        className="absolute-link"
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedEvent({
                            event_title: item.event_title.text,
                            event_time: item.event_time,
                            event_date: formatDate(item.event_date),
                            event_description: item.event_description,
                            event_address: item.event_address,
                            event_google_map_url: item.google_map_url,
                          });
                        }}
                      >
                        <span>More details</span>{" "}
                        <span>
                          <BsArrowRight />
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        ) : (
          <section className="upcoming-events upcoming-events--no-event">
            <div className="inner-wrapper">
              <h3 className="capital-subheading">
                NO UPCOMING EVENT AT THIS TIME
              </h3>
            </div>
          </section>
        )}

        <section className="past-events">
          <div className="inner-wrapper">
            <h2 className="capital-subheading">PAST EVENTS</h2>

            <ul className="past-events__categories-list">
              <li
                className={
                  selectedCategory === "All"
                    ? "category category--selected"
                    : "category"
                }
                onClick={() => setSelectedCategory("All")}
              >
                ALL
              </li>
              {allCategories.map((item, i) => (
                <li
                  className={
                    selectedCategory === item
                      ? "category category--selected"
                      : "category"
                  }
                  key={item + i}
                  onClick={() => setSelectedCategory(item)}
                >
                  {item}
                </li>
              ))}
            </ul>

            <div className="past-events__categories-list-mobile-dropdown">
              <p
                className="placeholder"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedCategory}{" "}
                <span className="placeholder__arrow">
                  <BsArrowDown />
                </span>
              </p>
              {isDropdownOpen && (
                <ul className="dropdown-list">
                  <li
                    className={
                      selectedCategory === "All"
                        ? "category category--selected"
                        : "category"
                    }
                    onClick={() => {
                      setSelectedCategory("All");
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                  >
                    <span>All</span>
                  </li>
                  {allCategories.map((item, i) => (
                    <li
                      className={
                        selectedCategory === item
                          ? "category category--selected"
                          : "category"
                      }
                      key={item + i}
                      onClick={() => {
                        setSelectedCategory(item);
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                    >
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <FlipMove
              enterAnimation={`accordionVertical`}
              leaveAnimation={`fade`}
              className="event-list"
            >
              {filteredPastEvents.map((item) => (
                <li
                  className="event-list__each"
                  key={item.node.id}
                  onClick={() =>
                    navigate(
                      `/events/${toSlug(item.node.data.event_title.text)}`
                    )
                  }
                >
                  <div className="img-wrapper">
                    {item.node.data.gallery_list.length > 0 ? (
                      item.node.data.gallery_list[0].image.thumbnails.square
                        .gatsbyImageData ? (
                        <GatsbyImage
                          className="desktop"
                          image={
                            item.node.data.gallery_list[0].image.thumbnails
                              .square.gatsbyImageData
                          }
                          alt={
                            item.node.data.gallery_list[0].image.alt ||
                            "Thumbnail"
                          }
                        />
                      ) : (
                        <FallbackImgSquare className="desktop" />
                      )
                    ) : (
                      <FallbackImgSquare className="desktop" />
                    )}
                    {item.node.data.gallery_list.length > 0 ? (
                      item.node.data.gallery_list[0].image.thumbnails.thumbnail
                        .gatsbyImageData ? (
                        <GatsbyImage
                          className="mobile"
                          image={
                            item.node.data.gallery_list[0].image.thumbnails
                              .thumbnail.gatsbyImageData
                          }
                          alt={
                            item.node.data.gallery_list[0].image.alt ||
                            "Thumbnail"
                          }
                        />
                      ) : (
                        <FallbackImgLandscape className="mobile" />
                      )
                    ) : (
                      <FallbackImgLandscape className="mobile" />
                    )}
                  </div>
                  <div className="overlay">
                    <h3 className="heading">
                      {item.node.data.event_title.text}
                    </h3>
                    <button className="btn">SEE MORE</button>
                  </div>
                  <h4 className="title-on-mobile">
                    {item.node.data.event_title.text}
                  </h4>
                  <StyledButton
                    className="mobile-btn"
                    onClick={() =>
                      navigate(
                        `/events/${toSlug(item.node.data.event_title.text)}`
                      )
                    }
                  >
                    SEE EVENT
                  </StyledButton>
                </li>
              ))}
            </FlipMove>
          </div>
        </section>

        <ModalEvent
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
        />

        <Cta heading={"Want to book an event?"} />
      </Wrapper>
    </Layout>
  );
};

export default EventsPage;

export const dataQuery = graphql`
  {
    upcomingEvents: prismicUpcomingEvents {
      data {
        event_list {
          event_title {
            text
          }
          event_date
          event_time
          event_address
          google_map_url
          event_description {
            html
          }
        }
      }
    }
    pastEvents: allPrismicEvent(
      sort: { fields: data___event_date, order: DESC }
    ) {
      edges {
        node {
          id
          data {
            event_title {
              text
            }
            category {
              document {
                ... on PrismicCategory {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            gallery_list {
              image {
                alt
                url
                thumbnails {
                  square {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  thumbnail {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            event_date
            event_time
            event_address
            google_map_url
          }
        }
      }
    }
    categories: allPrismicCategory {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
