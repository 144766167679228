import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import {
  mainRed,
  mainBlack,
  screen,
  mainYellow,
  mainWhite,
  innerWidth,
} from "../common/variables";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.section`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 41px 0 50px 0;
    display: flex;
    flex-direction: column;
    @media ${screen.xsmall} {
      padding: 100px 42px;
    }
    @media ${screen.small} {
      align-items: center;
      flex-direction: row;
      padding: 100px 30px;
    }
    @media ${screen.large} {
      padding: 110px 0;
    }

    .col {
      width: 100%;
      @media ${screen.small} {
        width: 45%;
      }

      .capital-subheading {
        text-align: center;
        @media ${screen.small} {
          max-width: 620px;
          text-align: left;
        }
      }

      .normal-p {
        margin: 18px 0 0 0;
        text-align: center;
        padding: 0 22px;
        @media ${screen.small} {
          text-align: left;
          padding: 0;
          max-width: 490px;
        }
      }

      &--right {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 30px 0 0 0;
        @media ${screen.xsmall} {
          flex-direction: row;
        }
        @media ${screen.small} {
          justify-content: flex-end;
          width: 55%;
          margin: 0;
        }

        a {
          background: none;
          border: 2px ${mainRed} solid;
          color: ${mainBlack};
          display: inline-block;
          font-size: 1.02rem;
          font-weight: 600;
          padding: 13px 23px 12px 23px;
          border-radius: 5px;
          letter-spacing: 3px;
          outline: none;
          cursor: pointer;
          line-height: 20px;
          width: 215px;
          margin: 0 auto 18px auto;
          text-align: center;
          @media ${screen.xsmall} {
            min-width: 250px;
            margin: 0 20px;
          }
          @media ${screen.small} {
            margin: 0 0 0 65px;
          }
          @media ${screen.large} {
            padding: 15px 26px 14px 26px;
            font-size: 1.1rem;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainYellow};
              border: 2px ${mainYellow} solid;
              color: ${mainWhite};
            }
          }
        }
      }
    }
  }
`;

const Cta = ({ className, heading, description }) => {
  const { email_address, phone_number } = useStaticQuery(
    dataQuery
  ).contact.data;

  return (
    <Wrapper className={className}>
      <div className="inner-wrapper">
        <div className="col">
          <h3 className="capital-subheading">{heading || "Heading"}</h3>
          {description && <p className="normal-p">{description}</p>}
        </div>

        <div className="col col--right">
          <a href={`tel:${phone_number}`}>CALL</a>
          <Obfuscate email={email_address}>EMAIL</Obfuscate>
        </div>
      </div>
    </Wrapper>
  );
};

export default Cta;

const dataQuery = graphql`
  {
    contact: prismicGeneralDetails {
      data {
        email_address
        phone_number
      }
    }
  }
`;
